<template>
  <div style="text-align: left; margin-top: 15px;">
    <div v-if="showBreakdowns" class="grey lighten-5 section">
      <h2 style="text-align: center;">Bulk Order Quantity</h2>
      <v-form @submit.prevent="save">
        <size-breakdown
          :totalError="invalidBulk"
          :isSizeRequired="true"
          :breakdowns="model.bulkBreakdowns"
          :showBreakdowns="true"
          :sizeChart="sizeChart"
          quantityTitle="Bulk Quantity"
          :min="0"
          :max="100000000"
          :showEdit="showEdit"
          @getTotal="$emit('getTotal', ...arguments)"
          @update-breakdowns="updateBulkBreakdowns"
        >
        </size-breakdown>

        <v-btn type="submit" v-if="showEdit" class="save_button">Save</v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import notification from '../../notification';
import restAdapter from '../../restAdapter';
import SizeBreakdown from '../SizeBreakdown.vue';
import routes from '../../router/routes';

export default {
  name: "CostSheetBreakdowns",
  components: {
    SizeBreakdown
  },
  props: {
    inquiryId: {
      type: [String, Number],
      required: true
    },
    showEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sizeChart: {sizes: []},
      invalidBulk: false,
      totalQtySaveError: false,
      showBreakdowns: false,
      model: {
        sampleBreakdowns: [],
        bulkBreakdowns: []
      }
    }
  },
  watch: {
    inquiryId(value){
      if(!value) return;
      this.getBreakdowns().then(response => {
        this.setInitialBreakdownsAndColors(response.data)
      })
      .catch((error) => {
        if(error.response.status === 401){
          notification.error("You don't have Access");
          this.$router.push({name: routes.COLLECTION_LIST});
        }
      });
    }
  },
  methods: {
    validateQuantity(value){
      if (!value) {
        return false;
      }
      value = parseInt(value);
      if (value >= 50 && value <= 100000000) {
        return true;
      }
      return false;
    },
    resetErrors(){
      this.model.bulkBreakdowns = this.model.bulkBreakdowns.map((item, index) =>{
        return {...this.model.bulkBreakdowns[index], error: false};
      });
      return;
    },
    checkTotalBulkList(){
      for(let i = 0; i<this.model.bulkBreakdowns.length; i++){
        let allNull = true;
        this.totalQtySaveError = false;

        if(!this.model.bulkBreakdowns[i].color){
          this.model.bulkBreakdowns = this.model.bulkBreakdowns.map((item, index) => {
            if (index == i) {
              return {...this.model.bulkBreakdowns[index], colorError: true};
            }
            return {...this.model.bulkBreakdowns[index]};
          });
          this.totalQtySaveError = true;
          return;
        }

        for(let j = 0; j<this.model.bulkBreakdowns[i].sizeAndQuantity.length; j++){
          if(this.model.bulkBreakdowns[i].sizeAndQuantity[j].quantity && parseInt(this.model.bulkBreakdowns[i].sizeAndQuantity[j].quantity)){
            allNull = false;
            this.totalQtySaveError = false;
          }
        }
        if(allNull){
          this.model.bulkBreakdowns = this.model.bulkBreakdowns.map((item, index) =>{
            if(index == i){
              return {...this.model.bulkBreakdowns[index], error: true};
            }
            return {...this.model.bulkBreakdowns[index]};
          });
          this.totalQtySaveError = true;
          return;
        }
      }
    },
    save(){
      const bulkQuantity = this.calculateTotalQty(this.model.bulkBreakdowns)
      this.invalidBulk = !this.validateQuantity(bulkQuantity)
      if(this.invalidBulk){
        return;
      }

      this.checkTotalBulkList();
      if(this.totalQtySaveError) {
        return;
      }
      const formData = new FormData();
      formData.append("sample_quantity", this.calculateTotalQty(this.model.sampleBreakdowns));
      formData.append("total_quantity", bulkQuantity);
      formData.append(
        "sample_breakdowns",
        JSON.stringify(this.model.sampleBreakdowns)
      );
      formData.append(
        "bulk_breakdowns",
        JSON.stringify(this.model.bulkBreakdowns)
      );

      this.saveBreakdowns(formData).then(() => {
        this.resetErrors();
        notification.success('Bulk Breakdowns Saved Successfully')
      }).catch((error) => {
        if(error.response.status === 401){
          notification.error("You don't have Access");
          this.$router.push({name: routes.COLLECTION_LIST});
        }
        else{
          notification.error("Something went wrong");
        }
      });
    },
    updateBulkBreakdowns(breakdowns){
      this.model.bulkBreakdowns = breakdowns;
      this.model.bulkBreakdowns = this.model.bulkBreakdowns.map(breakdown => breakdown);
    },
    calculateTotalQty(breakdowns){
      let totalBulkValue = 0;
      breakdowns.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if(item.quantity){
            totalValue = parseFloat(item.quantity);
          }
          totalBulkValue = totalBulkValue + totalValue;
        });
      });
      return totalBulkValue;
    },
    getBreakdowns(){
      return restAdapter.get(`/api/costing/show_breakdown/${this.inquiryId}`)
    },
    saveBreakdowns(payload){
      return restAdapter.post(`/api/costing/save_breakdown/${this.inquiryId}`, payload)
    },
    setInitialBreakdownsAndColors(data){
      this.sizeChart = {...this.sizeChart, sizes: data.bulkBreakdowns[0].sizeAndQuantity.map(sizeAndQuantity => sizeAndQuantity.size)}
      this.showBreakdowns = data.bulkBreakdowns.length;
      this.model.bulkBreakdowns = data.bulkBreakdowns;
      this.model.sampleBreakdowns = data.sampleBreakdowns;
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  padding: 20px 50px 50px 50px;
}
.save_button {
  background-color: #492a67 !important;
  border: #492a67 !important;
  display:flex;
  float: right;
}
.fabric{
  float:"left";
}
.techpack{
  float:"left";
}
</style>
